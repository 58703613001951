import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		name: 'homePage',
		meta: {
			title: '遵义市专业技术人员继续教育平台'
		},
		component: () => import('../views/homePage.vue'),
		redirect:"/index",
		children: [{ //首页
			path: '/index',
			name: 'index',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/index.vue')
		},{//新闻列表页
			path: '/newsList',
			name: 'newsList',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/newsList.vue')
		},{//新闻详情页
			path: '/newsDetails',
			name: 'newsDetails',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/newsDetails.vue')
		},{//注册页
			path: '/register',
			name: 'register',
			meta: {
				title: '遵义市专业技术人员继续教育平台-注册'
			},
			component: () => import('../views/register.vue')
		},{//课程列表页
			path: '/WcourseList',
			name: 'WcourseList',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/courseList.vue')
		},{//课程详情页
			path: '/courseDetails',
			name: 'courseDetails',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/courseDetails.vue')
		},{//找回密码
			path: '/findPassword',
			name: 'findPassword',
			meta: {
				title: '遵义市专业技术人员继续教育平台-找回密码'
			},
			component: () => import('../views/findPassword.vue')
		}]
	},
	{
		path: '/LearningCenter',
		name: 'LearningCenter',
		meta: {
			title: '遵义市专业技术人员继续教育平台',
			requireAuth: true
		},
		component: () => import('../views/LearningCenter/learningCenter.vue'),
		redirect:"/course",
		children: [{ //课程
			path: '/course',
			name: 'course',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/LearningCenter/course.vue')
		},{ //学习记录
			path: '/LearningRecords',
			name: 'LearningRecords',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/LearningCenter/LearningRecords.vue')
		},{ //订单
			path: '/order',
			name: 'order',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/LearningCenter/order.vue')
		},{ //证书
			path: '/certificate',
			name: 'certificate',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/LearningCenter/certificate.vue')
		},{ //消息
			path: '/message',
			name: 'message',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/LearningCenter/message.vue')
		},{ //设置
			path: '/setup',
			name: 'setup',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/LearningCenter/setup.vue')
		},{ //练习
			path: '/practice',
			name: 'practice',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/LearningCenter/practice.vue')
		},{ //考试
			path: '/exam',
			name: 'exam',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/LearningCenter/exam.vue')
		},]
	},
	{
		path: '/CourseCenter',
		name: 'CourseCenter',
		meta: {
			title: '遵义市专业技术人员继续教育平台',
		},
		component: () => import('../views/CourseCenter/CourseCenter.vue'),
		redirect:"/courseList",
		children: [{ //课程列表
			path: '/courseList',
			name: 'courseList',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/CourseCenter/courseList.vue')
		},{ //课程详情
			path: '/courseInfo',
			name: 'courseInfo',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/CourseCenter/courseInfo.vue')
		},{ //购物车
			path: '/myCart',
			name: 'myCart',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/CourseCenter/myCart.vue')
		},{ //确认订单
			path: '/confirmOrder',
			name: 'confirmOrder',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/CourseCenter/confirmOrder.vue')
		},{ //扫码支付
			path: '/payment',
			name: 'payment',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/CourseCenter/payment.vue')
		},{ //专题列表
			path: '/specialList',
			name: 'specialList',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/CourseCenter/specialList.vue')
		},{ //专题详情
			path: '/specialInfo',
			name: 'specialInfo',
			meta: {
				title: '遵义市专业技术人员继续教育平台'
			},
			component: () => import('../views/CourseCenter/specialInfo.vue')
		},{ //专题确认订单
			path: '/confirmOrderZt',
			name: 'confirmOrderZt',
			meta: {
				title: '遵义市专业技术人员继续教育平台',
				requireAuth: true
			},
			component: () => import('../views/CourseCenter/confirmOrderZt.vue')
		}]
	},{ //播放器
		path: '/videoPlay',
		name: 'videoPlay',
		meta: {
			title: '遵义市专业技术人员继续教育平台',
			requireAuth: true
		},
		component: () => import('../views/video/videoPlay.vue'),
	},{ //集体报名
		path: '/GroupRegistration',
		name: 'GroupRegistration',
		meta: {
			title: '遵义市专业技术人员继续教育平台'
		},
		component: () => import('../views/GroupRegistration.vue'),
	},
	{
		path: '*', // 404 页面
		component: () => import('../views/notFind.vue'),
	},
]

const router = new VueRouter({
  mode: 'history',
  base: "/web",
  routes
})

export default router


import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import VueWechatTitle from 'vue-wechat-title'
import VueAMap from 'vue-amap'
import VueResource from 'vue-resource'
import PublicJs from '@/views/js/public.js'
import VueCookies from 'vue-cookies'
import Print from 'vue-print-nb'

Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.use(VueWechatTitle)
Vue.use(VueAMap)
Vue.use(VueResource)
Vue.prototype.PublicJs = PublicJs
Vue.use(VueCookies)
Vue.use(Print)

VueAMap.initAMapApiLoader({
  key: 'b15dd67aa80d4bc486d5ecb9c3b962d2', // 自己到官网申请，我随便写的
  v: '1.4.4',//高德 sdk 版本为 1.4.4
});

router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
		if(sessionStorage.token) { // 判断当前的token是否存在 ； 登录存入的token
			next();
		} else {
			next({
				path: '/'
			})
		}
	} else {
		next();
	}
});

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')

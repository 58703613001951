import CryptoJS from 'crypto-js'
export default {
	// publicPath: "https://gzqdn.brjxjy.com/",
	publicPath: window.location.origin,
	zzUrl:"gzzyftd.brjxjy.com",
	isNotice:"QDNisNotice",//是否已经进入过
	isbeOnline:"QDNisbeOnline",//是否弹出公告
	//加密
	Encrypt(str) {
		var data = str.toString();
		var iv = CryptoJS.enc.Utf8.parse('ZZWBKJ_ZHIHUAWEI');
		var key = CryptoJS.enc.Utf8.parse('20180227110419WB');
		var encrypted = CryptoJS.AES.encrypt(data, key, { iv:iv,padding:CryptoJS.pad.Pkcs7 });
		var rest = encrypted.toString();
		return rest;
	},
	//解密
	Decrypt(str) {
		var iv = CryptoJS.enc.Utf8.parse('ZZWBKJ_ZHIHUAWEI');
		var key = CryptoJS.enc.Utf8.parse('20180227110419WB');
		var decrypted = CryptoJS.AES.decrypt(str,key,{iv:iv,padding:CryptoJS.pad.Pkcs7});
		var rest =  decrypted.toString(CryptoJS.enc.Utf8);
		return rest;
	},
	//身份证验证
	IdentityCodeValid(code){
		var city = { 11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江 ", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北 ", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏 ", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外 " };
		var pass = true;
		if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
			pass = false;
		}else if (!city[code.substr(0, 2)]) {
			pass = false;
		}else {
			if (code.length == 18) {
				code = code.split('');
				var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
				var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
				var sum = 0;
				var ai = 0;
				var wi = 0;
				for (var i = 0; i < 17; i++) {
					ai = code[i];
					wi = factor[i];
					sum += ai * wi;
				}
				var last = parity[sum % 11];
				if (parity[sum % 11] != code[17]) {
					pass = false;
				}
			}
		}
		return pass;
	},
	//手机号码验证
	phoneCheck(val){
		//手机号正则
		var reg = /^1[345789]\d{9}$/;
		if (!reg.test(val)) {
			return false;
		} else {
			return true;
		}
	},
	//邮箱验证
	isEmailAvailable(val) {
		var myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
		if (!myreg.test(val)) {
			return false;
		}else {
			return true;
		}
	},
	//秒转时分秒
	formatSeconds(value) {
	    let result = parseInt(value)
	    let h = Math.floor(result / 3600) < 10 ?  Math.floor(result / 3600) : Math.floor(result / 3600);
	    let m = Math.floor((result / 60 % 60)) < 10 ?  Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
	    let s = Math.floor((result % 60)) < 10 ?  Math.floor((result % 60)) : Math.floor((result % 60));
	 
	    let res = '';
	    if(h !== 0) res += `${h}h`;
	    if(m !== 0) res += `${m}min`;
	    res += `${s}s`;
	    return res;
	},
	//秒转时分秒
	ZHformatSeconds(value) {
	    let result = parseInt(value)
	    let h = Math.floor(result / 3600) < 10 ?  Math.floor(result / 3600) : Math.floor(result / 3600);
	    let m = Math.floor((result / 60 % 60)) < 10 ?  Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
	    let s = Math.floor((result % 60)) < 10 ?  Math.floor((result % 60)) : Math.floor((result % 60));
	 
	    let res = '';
	    if(h !== 0) res += `${h}时`;
	    if(m !== 0) res += `${m}分`;
	    res += `${s}秒`;
	    return res;
	},
	//显示用时
	totalSeconds(val){
		let result = parseInt(val)
		let h = Math.floor(result / 3600) < 10 ?  Math.floor(result / 3600) : Math.floor(result / 3600);
		let m = Math.floor((result / 60 % 60)) < 10 ?  Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
		let s = Math.floor((result % 60)) < 10 ?  Math.floor((result % 60)) : Math.floor((result % 60));
			 
		let res = '';
		if(h !== 0){
			res += h.toString().length == 1 ? `0${h}:` : `${h}:`;
		}else{
			res += `00:`;
		}
		if(m !== 0){
			res += m.toString().length == 1 ? `0${m}:` : `${m}:`;
		}else{
			res += `00:`;
		}
		res += s.toString().length == 1 ? `0${s}` : `${s}`;
		return res;
	},
	//判断当前时间是否在某一时间段内
	isDuringDate(beginDateStr, endDateStr) {
		beginDateStr = beginDateStr.replace(/\-/g, '/');
		endDateStr = endDateStr.replace(/\-/g, '/');
		var curDate = new Date(),
			beginDate = new Date(beginDateStr),
			endDate = new Date(endDateStr);
		if (curDate >= beginDate && curDate <= endDate) {
			return true;
		}
		return false;
	},
	//去除数组中空值
	trimSpace(array){  
	    for(var i = 0 ;i<array.length;i++){  
	        if(array[i] == " " || array[i] == null || typeof(array[i]) == "undefined"){  
				array.splice(i,1);  
				i= i-1;  
	        }  
	    }  
	     return array;  
	},
	isExam:false,//是否在考试界面
	//是否在数组中存在
	ArrayIndexOf(val,arr){
		for (var i = 0; i < arr.length; i++) {
			if (arr[i] == val) return i;
		}
		return -1;
	},
	//在数组中删除
	ArrayRemove(val,arr) {
		var index = arr.indexOf(val);
		if (index > -1) {
			arr.splice(index, 1);
		}	
	},
	getCurrentTime() {
		var dt = new Date();
		var year = dt.getFullYear();
		var month = dt.getMonth() + 1;
		var date = dt.getDate();
		return year + "-" + this.fnW(month) + "-" + this.fnW(date) ;
	},
	//补位 当某个字段不是两位数时补0
	fnW(str) {
		var num;
		str > 9 ? num = str : num = "0" + str;
		return num;
	},
	//当前设备
	getOsInfo() {
	    var userAgent = navigator.userAgent.toLowerCase();
	    var name = 'Unknown';
	    var version = 'Unknown';
	    if (userAgent.indexOf('windows') > -1) {
	        name = 'Windows';
	        if (userAgent.indexOf('windows nt 5.0') > -1) {
	            version = 'Windows 2000';
	        } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
	            version = 'Windows XP';
	        } else if (userAgent.indexOf('windows nt 6.0') > -1) {
	            version = 'Windows Vista';
	        } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
	            version = 'Windows 7';
	        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
	            version = 'Windows 8';
	        } else if (userAgent.indexOf('windows nt 6.3') > -1) {
	            version = 'Windows 8.1';
	        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
	            version = 'Windows 10';
	        } else {
	            version = 'Unknown';
	        }
	    } else if (userAgent.indexOf('iphone') > -1) {
	        name = 'Iphone';
	    } else if (userAgent.indexOf('mac') > -1) {
	        name = 'Mac';
	    } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 ) {
	        name = 'Unix';
	    } else if (userAgent.indexOf('linux') > -1) {
	        if (userAgent.indexOf('android') > -1) {
	            name = 'Android';
	        } else {
	            name = 'Linux';
	        }
	    } else {
	        name = 'Unknown';
	    }
	    return {
	        name: name,
	        version: version
	    };
	},
}
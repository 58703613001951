<template>
	<div id="app" @contextmenu.prevent>
		<router-view v-wechat-title="routeName" v-if="configInfo"></router-view>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				routeName:"贵州电子信息职业技术学院继续教育平台",
				configInfo:null,//网站配置信息
				name:""
			}
		},
		methods: {
			//网站配置信息
			getConfigInfo(are_code){
				this.$http.post(this.PublicJs.publicPath + "/api/website.index/configInfo.html", {
					are_code:are_code,
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.configInfo = response.data.data;
						window.sessionStorage.setItem('configInfo',this.PublicJs.Encrypt(JSON.stringify(this.configInfo)))//网站配置信息
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
		},
		created() {
			if(this.PublicJs.getOsInfo().name == "Iphone" || this.PublicJs.getOsInfo().name == "Android"){
				window.location.href = window.location.origin + "/mobile";
			}
			this.routeName = "贵州电子信息职业技术学院继续教育平台";
			this.$cookies.set("are_code","QDN");
			this.$cookies.set("cityName","黔东南");
			//网站配置信息
			this.getConfigInfo(this.$cookies.get("are_code"))
		}
	}
</script>

<style>
@import "./font/font.css";
*{
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}
html,#app,body,.app_div{
	width: 100%;
	height: 100%;
}
body{
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	position: relative;
}
img{
	border: none;
}
a{
	text-decoration: none;
}
li{
	list-style: none;
}
.fb{
	font-weight: bold;
}
.fs_16{
	font-size: 16px;
}
.fs_18{
	font-size: 18px;
}
.c_333{
	color: #333;
}
.mt_22{
	margin-top: 22px;
}
.mb_16{
	margin-bottom: 16px;
}
.el-popconfirm__main{
	margin: 14px 0;
}
.scoreDialog .el-dialog__header,
.scoreDialog .el-dialog__body,
.answerDialog .el-dialog__header,
.answerDialog .el-dialog__body{
	padding: 0;
}
.clearFix{
	clear: both;
}
</style>
